const tags = [
  {
    id: '01',
    type: 'video',
    face: { x: -0.1417703579590224, y: 0.9886208243646433, z: 0.05029941586881483 },
    position: { x: -3.781527745447004, y: 0.8428246057846831, z: 14.62900093641922 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/1.%20INTRO.mp4',
    },
  },
  {
    id: '02',
    type: 'video',
    face: { x: 0.0520646975675666, y: 0.9984135763469223, z: 0.021438232980967045 },
    position: { x: -3.3163826476440685, y: 0.4100135900287327, z: 17.26403652007096 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/2.%20SYMMETRY.mp4',
    },
  },
  {
    id: '03',
    type: 'video',
    face: { x: -0.01792967042433085, y: 0.9984510925030521, z: 0.052668233290443726 },
    position: { x: -2.7929425417255422, y: 0.7899630075939207, z: 22.032505835146207 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/3.%20SIMPLIFIED%20LAYOUTS.mp4',
    },
  },
  {
    id: '04',
    type: 'video',
    face: { x: -0.0851066745316133, y: 0.13447527243327917, z: -0.9872554153075916 },
    position: { x: -1.9008957778446556, y: 1.278679644936944, z: 26.358341316631535 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/4.%20BIG%20IDEA%20SHOP.mp4',
    },
  },
  {
    id: '05',
    type: 'video',
    face: { x: -0.8423798344406945, y: 0.5129288346819291, z: -0.16522779753875103 },
    position: { x: 3.0045860065520222, y: 1.454155961353665, z: 17.395947425261497 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/5.%20TORSO%20FRAMEWORK%201%20v2.mp4',
    },
  },
  {
    id: '06',
    type: 'video',
    face: { x: -0.847163885988552, y: 0.493717380034883, z: -0.1963580885226442 },
    position: { x: 6.01495072891135, y: 1.7491061167130053, z: 15.347266670568652 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/6.%20TORSO%20FRAMEWORK%202.mp4',
    },
  },
  {
    id: '07',
    type: 'video',
    face: { x: -0.17841395833799237, y: 0.9593052092849235, z: 0.21886519803061036 },
    position: { x: 3.778513726986051, y: 0.5120492019315979, z: 7.070043931133815 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/7.%20SIMPLIFIED%20LAYOUTS.mp4',
    },
  },
  {
    id: '08',
    type: 'video',
    face: { x: -0.5948250439713938, y: 0.38251375906123686, z: 0.7070122991810477 },
    position: { x: 3.674452968725471, y: 1.4957333935100978, z: 9.052501562654498 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/8.%20TORSO%20FRAMEWORK%203.mp4',
    },
  },
  {
    id: '09',
    type: 'video',
    face: { x: -0.030577675827137052, y: 0.9937865425568011, z: -0.1070201549896564 },
    position: { x: 7.313864121155996, y: 1.2137762380863177, z: 7.555319929456382 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/9.%20SEASONAL%20SHOP.mp4',
    },
  },
  {
    id: '10',
    type: 'video',
    face: { x: 0.024790097437452165, y: 0.9991439664288491, z: -0.03311775079724748 },
    position: { x: 11.513199277051926, y: 0.86792576041222, z: 22.06302711783957 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/11.%20HERO%20DISPLAY.mp4',
    },
  },
  {
    id: '11',
    type: 'video',
    face: { x: 0.011855737500830374, y: 0.13337750599620626, z: -0.9909943906917654 },
    position: { x: 11.909546933801284, y: 1.3052578779075783, z: 24.585123834746934 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/10.%20HERO%20CATEGORY%20SHOP.mp4',
    },
  },
  {
    id: '12',
    type: 'video',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 27.479463845363938, y: 0.4512220621109009, z: 23.63798096488001 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/12.%20SEASONAL%20SHOP.mp4',
    },
  },
  {
    id: '13',
    type: 'video',
    face: { x: -0.07886184488196474, y: 0.9831054406780398, z: 0.16518021047041342 },
    position: { x: 34.667607600928285, y: 1.197818114196477, z: 16.506685240615948 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/13.%20SYMMETRY.mp4',
    },
  },
  {
    id: '14',
    type: 'video',
    face: { x: -0.04092317083945638, y: 0.9990834001965775, z: -0.01255601609149787 },
    position: { x: 44.89365098731519, y: 0.7400760619747134, z: 19.180158678364965 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/14.%20SIMPLIFIED%20LAYOUTS.mp4',
    },
  },
  {
    id: '15',
    type: 'video',
    face: { x: -0.5359949760011241, y: 0.8422656197871936, z: 0.057428315542502474 },
    position: { x: 52.38610995052317, y: 1.1059840279509041, z: 15.171029094716292 },
    media: {
      src:
        'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/videos/15.%20BIG%20IDEA%20DISPLAY.mp4',
    },
  },
];

export default tags;
