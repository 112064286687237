import React, { useMemo, useRef } from 'react';
import { DoubleSide, TextureLoader } from 'three';
import { Vector3 } from 'three';

import { useLoader } from '@react-three/fiber';

function ImagePlane({ url }) {
  const texture = useLoader(TextureLoader, url ?? '/assets/imgs/Towel_Pricepoint.png');
  const meshRef = useRef();
  const position = useMemo(
    () => new Vector3(28.181650513480624, 1.18630108807593, 23.298239458970415)
  );
  //{"x":28.16575849174181,"y":1.1900802584798484,"z":23.294673796524602},
  return (
    <mesh ref={meshRef} renderOrder={2} position={position} rotation={[0, Math.PI / 4, 0]}>
      <planeGeometry args={[0.45, 0.65]} />
      <meshBasicMaterial map={texture} side={DoubleSide} />
    </mesh>
  );
}

export default ImagePlane;
