import useLocations from '@features/3d-Space/locations/hooks/useLocations';
import useSpaceContext from '@hooks/useSpaceContext';
import navigationManager from '@utils/navigation.manager';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Box,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Slide,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AssistantOutlinedIcon from '@material-ui/icons/AssistantOutlined';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import PinDropOutlinedIcon from '@material-ui/icons/PinDropOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: 'none',
    width: 350,
    // height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    borderRadius: 0,
    zIndex: 101,
  },
  menuPaper: {
    padding: 24,
    height: 'calc( 100% - 48px)',
  },
  menuRoot: {
    position: 'relative',
    background: 'white',
    // minHeight: 320,
    // overflowY: 'scroll',
    '& .MuiMenuItem-root': {
      // paddingLeft: 24,
    },
  },
  menuScrollBar: {
    height: 'calc( 100% - 65px )',
    minHeight: 70,
  },
  // department: {
  //   padding: 0,
  //   '&:first-child li': {
  //     marginTop: theme.spacing(0),
  //   },
  //   '&:nth-child(2) li': {
  //     marginTop: theme.spacing(0.5),
  //   },
  // },
  subHeader: {
    marginTop: theme.spacing(1.5),
  },
  fontSubHeader: {
    fontWeight: 600,
    fontFamily: 'CircularBook, sans-serif',
    textTransform: 'uppercase',
  },
  fontHeader: {
    fontSize: '1rem',
    fontWeight: 700,
    color: '#212121',
    fontFamily: 'CircularBook, sans-serif',
    textTransform: 'uppercase',
    '& span': {
      color: 'black',
    },
  },
  menuIconContainer: {
    position: 'absolute',
    top: 40,
    right: 40,
    zIndex: 100,
  },
  menuIconButton: {
    background: 'transparent',
    // borderRadius: '25%',
    padding: 0,
    border: 'none',
    color: '#fff',
    '& .MuiSvgIcon-root': {
      width: 32,
      height: 32,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  animatedIconClose: {
    animation: `$myEffect 400ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedIconMenu: {
    animation: `$myEffectExit 400ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes myEffect': {
    '0%': {
      transform: 'rotateZ(90deg)',
      opacity: 0,
    },
    '100%': {
      opacity: 1,
      transform: 'rotateZ(0deg)',
    },
  },
  '@keyframes myEffectExit': {
    '0%': {
      opacity: 0,
      transform: 'rotateZ(-90deg)',
    },
    '100%': {
      opacity: 1,
      transform: 'rotateZ(0deg)',
    },
  },
  formControl: {
    paddingLeft: theme.spacing(2),
    // paddingBottom: theme.spacing(0.5),
    // width: '100%',
  },
  select: {
    width: 146,
    fontSize: 14,
    fontWeight: 'bold',
    '&.MuiSelect-select:focus': {
      backgroundColor: '#fff',
    },
  },
  closeIconButton: {
    background: 'transparent',
    // borderRadius: '25%',
    padding: 0,
    border: 'none',
    color: '#000',
    '& .MuiSvgIcon-root': {
      width: 18,
      height: 18,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  storeLogo: {
    position: 'absolute',
    bottom: 24,
    left: 0,
    // background: '#000',
    width: '100%',
    textAlign: 'center',
  },
  menuListIcon: {
    minWidth: 32,
    color: '#000',
  },
  subMenuItem: {
    whiteSpace: 'normal',
  },
}));

export const SidebarNavigation = (props) => {
  const classes = useStyles();
  const { spaceData } = useSpaceContext();
  const { getLocationByKey } = useLocations();

  const [openMenu, setOpenMenu] = useState(false);

  const handleToggleMenu = () => {
    setOpenMenu((prevState) => !prevState);
  };

  const handleMenuItemClick = (locationId, options, title) => {
    const location = getLocationByKey(locationId);
    if (location) {
      ReactGA.event('Click Menu', { title: title });
      navigationManager.requestNewLocation(location, false, options);
      setOpenMenu(false);
    }
  };

  return (
    <>
      <Slide direction="left" in={openMenu} timeout={500} mountOnEnter unmountOnExit>
        <Paper className={classes.root}>
          <Box className={classes.menuPaper}>
            <IconButton
              disableRipple
              className={classes.closeIconButton}
              onClick={handleToggleMenu}
              title="Close"
            >
              <CloseRoundedIcon className={classes.animatedIconClose} />
            </IconButton>
            <div className={classes.storeLogo}>
              <img src={spaceData?.logo || '/assets/imgs/linen-house-black.svg'} height={32} />
            </div>
            <PerfectScrollbar className={classes.menuScrollBar}>
              <Box className={classes.menuRoot}>
                {spaceData?.menus?.length > 0 &&
                  spaceData.menus.map((menuList, index) => {
                    return (
                      <MenuList key={index} className={classes.department}>
                        <MenuItem
                          {...(!menuList.locationId && { button: false })}
                          classes={{ root: classes.subHeader }}
                          {...(menuList.locationId && {
                            onClick: () =>
                              handleMenuItemClick(
                                menuList.locationId,
                                menuList.options,
                                menuList.title
                              ),
                          })}
                        >
                          <ListItemIcon
                            className={classes.menuListIcon}
                            // style={{
                            //   visibility: menuList.title != 'Entrance' ? 'hidden' : 'visible',
                            // }}
                          >
                            {menuList.title != 'Entrance' ? (
                              // <PinDropOutlinedIcon fontSize="small" />
                              <></>
                            ) : (
                              <HomeOutlinedIcon fontSize="small" />
                            )}
                          </ListItemIcon>
                          <Typography variant="body1" className={classes.fontSubHeader}>
                            {menuList.title}
                          </Typography>
                        </MenuItem>
                        {menuList.subMenus &&
                          menuList.subMenus.map((item) => {
                            return (
                              <MenuItem
                                onClick={() =>
                                  handleMenuItemClick(item.locationId, item.options, item.title)
                                }
                                key={`${item.locationId}`}
                                className={classes.subMenuItem}
                              >
                                <ListItemIcon className={classes.menuListIcon}>
                                  <PinDropOutlinedIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="subtitle2" wr>
                                  {item.title}
                                </Typography>
                              </MenuItem>
                            );
                          })}
                      </MenuList>
                    );
                  })}
                {/* <MenuItem classes={{ root: classes.subHeader }}>
                  <ListItemIcon className={classes.menuListIcon}>
                    <PanToolOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="body1" className={classes.fontSubHeader}>
                    {`About Us`}
                  </Typography>
                </MenuItem> */}
              </Box>
            </PerfectScrollbar>
          </Box>
        </Paper>
      </Slide>
      <Box className={classes.menuIconContainer}>
        <IconButton disableRipple className={classes.menuIconButton} onClick={handleToggleMenu}>
          <MenuRoundedIcon className={classes.animatedIconMenu} />
        </IconButton>
      </Box>
    </>
  );
};
