const products = [];
//   {
//     id: '5298895-02',
//     sku: '5298895-02',
//     title: 'Contrast Bind V Neck Tank',
//     price: '14.95',
//     imagePreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw78373118/5298895/5298895-02-2.jpg?sw=640&sh=960&sm=fit',
//     imageHoveredPreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw573f6ede/5298895/5298895-02-1.jpg?sw=640&sh=960&sm=fit',
//     url:
//       'https://factorie.com.au/contrast-bind-v-neck-tank/5298895-02.html?dwvar_5298895-02_color=5298895-02&cgid=womens-basics&originalPid=5298895-02',
//   },
//   {
//     id: '5298842-02',
//     sku: '5298842-02',
//     title: 'Short Sleeve Fitted Polo Tee',
//     price: '19.95',
//     imagePreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwe13b76bf/5298842/5298842-02-2.jpg?sw=640&sh=960&sm=fit',
//     imageHoveredPreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw33c9cb37/5298842/5298842-02-1.jpg?sw=640&sh=960&sm=fit',
//     url:
//       'https://factorie.com.au/short-sleeve-fitted-polo-tee/5298842-02.html?dwvar_5298842-02_color=5298842-02&cgid=womens-basics&originalPid=5298842-02',
//   },
//   {
//     id: '5298541-10',
//     sku: '5298541-10',
//     title: 'Longline Tank',
//     price: '14.95',
//     imagePreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw5bc5e3d2/5298541/5298541-10-2.jpg?sw=640&sh=960&sm=fit',
//     imageHoveredPreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw0e3029e3/5298541/5298541-10-1.jpg?sw=640&sh=960&sm=fit',
//     url:
//       'https://factorie.com.au/longline-tank/5298541-10.html?dwvar_5298541-10_color=5298541-10&cgid=womens-basics&originalPid=5298541-10',
//   },
//   {
//     id: '5298813-03',
//     sku: '5298813-03',
//     title: 'Pointelle Corset Tank',
//     price: '24.95',
//     imagePreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwc9e965d4/5298813/5298813-03-2.jpg?sw=640&sh=960&sm=fit',
//     imageHoveredPreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw0ba551ad/5298813/5298813-03-1.jpg?sw=640&sh=960&sm=fit',
//     url:
//       'https://factorie.com.au/pointelle-corset-tank/5298813-03.html?dwvar_5298813-03_color=5298813-03&cgid=womens-basics&originalPid=5298813-03',
//   },
//   {
//     id: '5298106-08',
//     sku: '5298106-08',
//     title: 'Fitted Crop Tee',
//     price: '19.95',
//     imagePreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwda14386d/5298106/5298106-08-2.jpg?sw=640&sh=960&sm=fit',
//     imageHoveredPreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw302cf0e8/5298106/5298106-08-1.jpg?sw=640&sh=960&sm=fit',
//     url:
//       'https://factorie.com.au/fitted-crop-tee/5298106-08.html?dwvar_5298106-08_color=5298106-08&cgid=womens-basics&originalPid=5298106-08',
//   },
//   {
//     id: '5298003-03',
//     sku: '5298003-03',
//     title: 'Racer Front Tank',
//     price: '14.95',
//     imagePreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw2469065e/5298003/5298003-03-2.jpg?sw=640&sh=960&sm=fit',
//     imageHoveredPreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwd9db0141/5298003/5298003-03-1.jpg?sw=640&sh=960&sm=fit',
//     url:
//       'https://factorie.com.au/racer-front-tank/5298003-03.html?dwvar_5298003-03_color=5298003-03&cgid=womens-basics&originalPid=5298003-03',
//   },
//   {
//     id: '5298640-05',
//     sku: '5298640-05',
//     title: 'Raglan Curve Hem Tank',
//     price: '19.95',
//     imagePreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwb2bc99aa/5298640/5298640-05-2.jpg?sw=640&sh=960&sm=fit',
//     imageHoveredPreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw792373d9/5298640/5298640-05-1.jpg?sw=640&sh=960&sm=fit',
//     url:
//       'https://factorie.com.au/raglan-curve-hem-tank/5298640-05.html?dwvar_5298640-05_color=5298640-05&cgid=womens-basics&originalPid=5298640-05',
//   },
//   {
//     id: '5298003-19',
//     sku: '5298003-19',
//     title: 'New Racer Front Tank',
//     price: '14.95',
//     imagePreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw8b8a1276/5298003/5298003-19-2.jpg?sw=640&sh=960&sm=fit',
//     imageHoveredPreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw93876670/5298003/5298003-19-1.jpg?sw=640&sh=960&sm=fit',
//     url:
//       'https://factorie.com.au/new-racer-front-tank/5298003-19.html?dwvar_5298003-19_color=5298003-19&cgid=womens-basics&originalPid=5298003-19',
//   },
//   {
//     id: '5298106-09',
//     sku: '5298106-09',
//     title: 'Fitted Crop Tee',
//     price: '19.95',
//     imagePreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw627404c1/5298106/5298106-09-2.jpg?sw=640&sh=960&sm=fit',
//     imageHoveredPreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwdc4680ba/5298106/5298106-09-1.jpg?sw=640&sh=960&sm=fit',
//     url:
//       'https://factorie.com.au/fitted-crop-tee/5298106-09.html?dwvar_5298106-09_color=5298106-09&cgid=womens-basics&originalPid=5298106-09',
//   },
//   {
//     id: '5298643-05',
//     sku: '5298643-05',
//     title: 'Rib Corset Tank',
//     price: '16.95',
//     imagePreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw5d53438c/5298643/5298643-05-2.jpg?sw=640&sh=960&sm=fit',
//     imageHoveredPreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw211d473a/5298643/5298643-05-1.jpg?sw=640&sh=960&sm=fit',
//     url:
//       'https://factorie.com.au/rib-corset-tank/5298643-05.html?dwvar_5298643-05_color=5298643-05&cgid=womens-basics&originalPid=5298643-05',
//   },
//   {
//     id: '5298466-07',
//     sku: '5298466-07',
//     title: 'Crop Jersey Bandeau',
//     price: '14.95',
//     imagePreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dw18260022/5298466/5298466-07-2.jpg?sw=640&sh=960&sm=fit',
//     imageHoveredPreview:
//       'https://factorie.com.au/dw/image/v2/BBDS_PRD/on/demandware.static/-/Sites-catalog-master-factorie/default/dwcc2f8dec/5298466/5298466-07-1.jpg?sw=640&sh=960&sm=fit',
//     url:
//       'https://factorie.com.au/crop-jersey-bandeau/5298466-07.html?dwvar_5298466-07_color=5298466-07&cgid=womens-basics&originalPid=5298466-07',
//   },
// ];

export default products;
