const store = {
  id: 'cotton-on-highpoint',
  name: 'COTTON ON - Highpoint',
  model:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/store-models/cotton-on-highpoint.glb',
  modelId: 'beba670c423244bcbcef7ee844310da1',
  storeFrontId: '5e728f6fde284d758238c234b3d93a83',
  storeLogo:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/assets/cotton-on-logo.svg',
  storeWelcomeText: 'Welcome to the Cotton On Highpoint Experience.',
  storeFavIcon:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/assets/cotton-on.ico',

  cameraRotation: { azimuthal: -93, polar: 90 },
  rotationDegrees: 178,
  menus: [
    {
      title: 'Entrance',
      locationId: '5e728f6fde284d758238c234b3d93a83',
      options: {
        rotation: { azimuthal: -7.899000228455016, polar: 1.4964751814539934 },
      },
    },
    {
      title: 'Womens',
      options: {
        rotation: { azimuthal: -7.899000228455016, polar: 1.4964751814539934 },
      },
      subMenus: [
        {
          title: 'F & C Event: Haven Program',
          locationId: 'edca77eb003b45839e2317637d5a847d',
          options: {
            rotation: { azimuthal: -1.5968621175680209, polar: 1.5619254300848044 },
          },
        },
        {
          title: 'Shop 1/2: Wide Leg & All Day',
          locationId: '27f7d2ecd13543adac2b00b03f2d2773',
          options: {
            rotation: {azimuthal: -2.357244972933056, polar: 1.3638534317540036},
          },
        },
        {
          title: 'Shop 1/2: Skirts & Sports',
          locationId: 'f7c09d923e61468aba2a817d8db72ea0',
          options: {
            rotation: {azimuthal: -2.9951069504482, polar: 1.480269443447571},
          },
        },
        {
          title: 'Shop 3/4: Denim Shorts',
          locationId: 'c616faab0e1140a4bbc5b865914546c0',
          options: {
            rotation: {azimuthal: -2.407870149395152, polar: 1.3872168352791863},
          },
        },
        {
          title: 'Shop 5/6: Bella & Graphics',
          locationId: '7a863337d51f4a3083a06213cd458906',
          options: {
            rotation: {azimuthal: -3.047127684174617, polar: 1.4781643746915345},
          },
        },
        {
          title: 'Shop 7/8: Wide Leg Denim',
          locationId: '0d8c6046b22247709cf1971c663c143d',
          options: {
            rotation: {azimuthal: -2.4018980486772006, polar: 1.3224707774948221},
          },
        },
        {
          title: 'Shop 9/10: Beach',
          locationId: '18c1844df1054c38b1aa6116e330f670',
          options: {
            rotation: {azimuthal: -1.966702950217576, polar: 1.4605579143139338},
          },
        },
        {
          title: 'Shop 11/12: Footwear & Accessories',
          locationId: '5e7551949e314ac2b7330eef64d57587',
          options: {
            rotation: {azimuthal: -2.4098081676715775, polar: 1.4369138954790037},
          },
        },
        {
          title: 'CZ 1: Boho - Dresses & Tops',
          locationId: 'cd1076594b684c6e8b0ef03195eb6fc7',
          options: {
            rotation: {azimuthal: -1.049269959956514, polar: 1.4817126021560278},
          },
        },
        {
          title: 'CZ 2: Baggy - Denim & Suiting Pants',
          locationId: 'f4015e89b15b4b7ba1ffb81d02ea8991',
          options: {
            rotation: {azimuthal: -1.5327386976552597, polar: 1.1942449507606165},
          },
        },
      ],
    },
    {
      title: 'Menswear',
      options: {
        rotation: { azimuthal: -7.899000228455016, polar: 1.4964751814539934 },
      },
      subMenus: [
        {
          title: 'F & C Event',
          locationId: 'e7bd4c6e07914be4b16c1d47a87a722d',
          options: {
            rotation: {azimuthal: -1.1966612515873811, polar: 1.3625502921886623},
          },
        },
        {
          title: 'Shop 1/2: Baggy Shorts & Shirts',
          locationId: '9b3f846d1bde4b8daa59688babeeace1',
          options: {
            rotation: {azimuthal: -0.8772414064822843, polar: 1.4308394022156579},
          },
        },
        {
          title: 'Shop 3: Linen & Beach',
          locationId: '242f14a85f7346208515273f2deac865',
          options: {
            rotation: {azimuthal: -0.9485849570554377, polar: 1.466452864885052},
          },
        },
        {
          title: 'Shop 4/5: Graphics & Utility',
          locationId: '1090da49bb7d4ea28c7bd4e6a3585896',
          options: {
            rotation: {azimuthal: -0.7769481330400327, polar: 1.4013403409581726},
          },
        },
        {
          title: 'Shop 6: Baggy Denim',
          locationId: 'e531f098a9aa42dc952b9034211f0ab0',
          options: {
            rotation: {azimuthal: -0.11152753247858199, polar: 1.292976470646561},
          },
        },
      ],
    },
    {
      title: 'Body',
      options: {
        rotation: { azimuthal: -7.899000228455016, polar: 1.4964751814539934 },
      },
      subMenus: [
        {
          title: 'Move F & C Event: Health & Wellness Hoodies',
          locationId: '5fc13a61c2fa40a09f719132a47bc829',
          options: {
            rotation: {azimuthal: -1.4831947994262298, polar: 1.2661926059080086},
          },
        },
        {
          title: 'Move CZ 1: Trackies',
          locationId: 'e311c55f710a4333af5892aecbc3f489',
          options: {
            rotation: {azimuthal: -1.5879528024879743, polar: 1.1793114964611553},
          },
        },
        {
          title: 'Move Shop 1/2: Tights & Crops',
          locationId: '062ba18c72b042328a66f30fd7bb4827',
          options: {
            rotation: {azimuthal: -1.4679289837933118, polar: 1.4438253872072402},
          },
        },
        {
          title: 'Move Shop 3/4: Active Shorts',
          locationId: '8ae1cd6e5e794eb38f5a3f1dea6541e0',
          options: {
            rotation: {azimuthal: -2.7445691123731306, polar: 1.23991679769768},
          },
        },
        {
          title: 'Relax Shop 1/2/3: Sleep Sets',
          locationId: '54986a6a3a554985b809ddffbb42adcd',
          options: {
            rotation: {azimuthal: -0.563209672185013, polar: 1.3240819670395592},
          },
        },
        {
          title: 'Relax Shop 4/5: Fashion Bras & Undies',
          locationId: 'e1547d8152224c16990057687578d84e',
          options: {
            rotation: {azimuthal: -0.6969447971896199, polar: 1.2700366684163098},
          },
        },
      ],
    },
  ],
  panoImageBaseUrl:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/location-images-equi/',
  panoImageFormat: '.webp',
  tiledImageSettings: {
    baseUrl512:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/location-images-tiled-2k/',
    baseUrl4k:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/location-images-tiled-4k/',
    baseUrl2k:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-highpoint/default/location-images-tiled-2k/',
  },
  layoutSettings: {
    modalAlignment: 'center',
  },
};

export default store;
