const nameTags = [
  {
    id: '01',
    text: 'F&C Event',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -3.6040112912531566, y: 0.8977132431046453, z: 7.599514969277305 },
  },
  {
    id: '02',
    text: 'F&C Event: Haven Program',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -3.2866812253210345, y: 0.8846371433682828, z: 14.771115064441286 },
  },
  {
    id: '03',
    text: 'Shop 1/2: Wide Leg & All Day',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -1.956940957785544, y: 0.7863617290803029, z: 21.928823868889882 },
  },
  {
    id: '04',
    text: 'Shop 1/2: Skirt & Sports',
    face: { x: 0, y: 1, z: 0 },
    position: { x: -0.645444357767988, y: -0.013828184064665017, z: 24.654626804760554 },
  },
  {
    id: '05',
    text: 'Shop 3/4: Denim Shorts',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 4.867351709388695, y: 0.0164034366607666, z: 21.31992807820763 },
  },
  {
    id: '06',
    text: 'CZ 1: Boho - Dresses & Tops',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 6.13470752220642, y: 0.4261947430150097, z: 14.724820949729587 },
  },
  {
    id: '07',
    text: 'Shop 5/6: Bella & Graphics',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 9.640555155076388, y: 1.3309533229922192, z: 22.202046692258858 },
  },
  {
    id: '08',
    text: 'CZ 2: Baggy - Denim & Suiting Pants',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 17.36130227568185, y: -0.0028363168239595726, z: 14.639798095946732 },
  },
  {
    id: '09',
    text: 'Shop 7/8: Wide Leg Denim',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 16.683360650824305, y: 0.20670112787814143, z: 21.71870661173989 },
  },
  {
    id: '10',
    text: 'Shop 9/10: Beach',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 23.523201417596866, y: 1.3181151236065012, z: 21.420917926647594 },
  },
  {
    id: '11',
    text: 'Shop 11/12: Footwear & Accessories',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 35.31449405222026, y: 0.10690183427670608, z: 19.754117589553733 },
  },
  {
    id: '12',
    text: 'Move Shop 3/4: Active Shorts',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 44.8858061421148, y: 1.0403799115174945, z: 19.768637499920374 },
  },
  {
    id: '13',
    text: 'Move Shop 1/2: Tights & Crops',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 49.64804291043572, y: 1.2932013593420268, z: 13.579066552406333 },
  },
  {
    id: '14',
    text: 'Move CZ 1: Trackies',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 45.71091223506606, y: 0.9981209934152242, z: 14.493989128714022 },
  },
  {
    id: '15',
    text: 'Move F&C Event: Health & Wellness Hoodies',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 34.74515700578878, y: 0.004859596490860207, z: 14.294716789360967 },
  },
  {
    id: '16',
    text: 'Relax Shop 4/5: Fashion Bras & Undies',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 43.3526094937367, y: 0.9970943439881167, z: 6.993573683430232 },
  },
  {
    id: '17',
    text: 'Relax Shop 1/2/3: Sleep Sets',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 35.4048633160622, y: 0.004859596490860207, z: 9.842151222896877 },
  },
  {
    id: '18',
    text: 'Dwell',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 28.378302062173702, y: 0.004859596490859985, z: 4.861176818598154 },
  },
  {
    id: '19',
    text: 'Shop 6: Baggy Denim',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 19.576434425550193, y: 0.9076362995594849, z: 9.085854919914821 },
  },
  {
    id: '20',
    text: 'Shop 4/5: Graphics & Utility',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 12.783486892660084, y: 0.008707553148269431, z: 9.142015245771551 },
  },
  {
    id: '21',
    text: 'Shop 3: Linen & Beach',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 7.610933690211049, y: 0.2406955619120339, z: 8.056329848710899 },
  },
  {
    id: '22',
    text: 'Shop 1/2: Baggy Shorts & Shirts',
    face: { x: 0, y: 1, z: 0 },
    position: { x: 4.076878263374482, y: 0.00540729494750547, z: 9.074287584396172 },
  },
];

export default nameTags;
