import { CameraContext } from '@contexts/CameraContext';
import { CurrentPositionContext } from '@contexts/CurrentPositionContext';
import { CustomControls, KeyboardControls } from '@features/3d-Space/controls';
import ImagePlane from '@features/3d-Space/images/components/ImagePlane';
import { Locations } from '@features/3d-Space/locations';
import useLocations from '@features/3d-Space/locations/hooks/useLocations';
import { Media } from '@features/3d-Space/media';
import NameTags from '@features/3d-Space/name-tags/components/NameTags';
import { Space } from '@features/3d-Space/space';
import { Tags } from '@features/3d-Space/tags';
import { Layout } from '@features/layout';
import { useCaches } from '@hooks/useCaches';
import useSpaceContext from '@hooks/useSpaceContext';
import React, { Suspense, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Physics } from '@react-three/cannon';
import { Canvas } from '@react-three/fiber';

const MainView = (props) => {
  const { spaceAlias, locationId } = useParams();
  const navigate = useNavigate();
  const { spaceId, setSpaceId, spaceData, spaceDBId, initializedDataStorage } = useSpaceContext();
  const { locationList } = useLocations();
  const [, setCameraContext] = useContext(CameraContext);
  const [, setCurrentPositionContext] = useContext(CurrentPositionContext);

  const [currentPoint, setCurrentPoint] = useState();
  const currentLocationIdRef = useRef();
  const cacheUrl = useCaches(spaceId, spaceData?.modelSource);

  const currentRotationRef = useRef();

  useEffect(() => {
    console.log('process.env.REACT_APP_SPACE_ALIAS', process.env.REACT_APP_SPACE_ALIAS);
    setSpaceId(spaceAlias ? spaceAlias : process.env.REACT_APP_SPACE_ALIAS);
  }, []);

  useEffect(() => {
    if (spaceId && spaceDBId && initializedDataStorage) {
      document.title = `${spaceData.name}`;

      setCameraContext(spaceData.cameraRotation);

      if (spaceData.favIcon) {
        let link = document.querySelector("link[rel~='icon']");
        link.href = spaceData.favIcon;
      }

      if (locationId) {
        navigate(`/${spaceId}/${locationId}`);
        currentLocationIdRef.current = locationId;
      } else {
        navigate(`/${spaceId}/${spaceData.storeFront}`);
        currentLocationIdRef.current = spaceData.storeFront;
      }
    }
  }, [spaceId, spaceDBId, initializedDataStorage]);

  useEffect(() => {
    if (spaceId && locationList) {
      if (currentLocationIdRef.current !== currentPoint?.locationKey) {
        const locationStart = locationList.find(
          (x) => x.locationKey == currentLocationIdRef.current
        );
        setCurrentPoint(locationStart);
      }
    }
  }, [locationList]);

  useEffect(() => {
    if (currentPoint?.position) {
      setCurrentPositionContext({ ...currentPoint.position });
    }
  }, [currentPoint]);

  const handleClickPoint = useCallback(
    (point) => {
      if (currentLocationIdRef.current != point.locationKey) {
        setCurrentPoint(point);
        currentLocationIdRef.current = point.locationKey;
        navigate(`/${spaceId}/${point.locationKey}`);
      }
    },
    [spaceId]
  );

  const handleRightMouseClickWorldLocation = useCallback(({ x, y, z }, face) => {
    const tag = {
      id: '',
      type: 'video',
      face: { x: face.x, y: face.y, z: face.z },
      position: { x, y, z },
      media: {
        src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/.MOV',
      },
    };
    console.log(JSON.stringify(tag));
  }, []);

  return (
    <Layout>
      {initializedDataStorage && cacheUrl && (
        <Canvas camera={{ fov: 70 }}>
          <Suspense fallback={null}>
            <ambientLight intensity={0.95} />
            <color attach="background" args={['#00001e']} />
            <Physics gravity={[0, -10, 0]}>
              {spaceData && (
                <CustomControls
                  cameraRotation={spaceData.cameraRotation}
                  currentRotationRef={currentRotationRef}
                />
              )}
              {locationList && (
                <Locations
                  locations={locationList}
                  handleClickPoint={handleClickPoint}
                  currentPoint={currentPoint}
                />
              )}

              <Space
                url={cacheUrl}
                handleRightMouseClickWorldLocation={handleRightMouseClickWorldLocation}
              />
              <Tags />
              {spaceData && <NameTags />}
              {spaceData && <ImagePlane />}
              {currentPoint && (
                <KeyboardControls
                  currentPosition={currentPoint.position}
                  currentPointId={currentPoint.locationKey}
                />
              )}
              {/* {spaceId && <Media spaceId={spaceId} />} */}
            </Physics>
          </Suspense>
        </Canvas>
      )}
    </Layout>
  );
};

export default MainView;
